.display-flex() {
    display: -webkit-flex;
    display: flex;
}

.display-inline-flex() {
    display: -webkit-inline-flex;
    display: inline-flex;
}

// @deprecated
.flex-container() {
    .display-flex();
}

.flex(@value: 1) {
    -webkit-flex: @value;
    flex: @value;
}

// @deprecated
.flex-item(@value: 1) {
    .flex(@value);
}

.flex-flow(@value) {
    -webkit-flex-flow: @value;
    flex-flow: @value;
}

.flex-direction(@value) {
    flex-direction: @value;
    -webkit-flex-direction: @value;
}

.flex-shrink(@value) {
    -webkit-flex-shrink: @value;
    flex-shrink: @value;
}

.align-items(@alignment) {
    align-items: @alignment;
    -webkit-align-items: @alignment;
}

.align-content(@alignment) {
    align-content: @alignment;
    -webkit-align-content: @alignment;
}


.justify-content(@alignment) {
    justify-content: @alignment;
    -webkit-justify-content: @alignment;
}


.dropshadow(@x-axis: 0, @y-axis: 1px, @blur: 2px, @fade: 10%, @color: #000) {
  -webkit-box-shadow: @x-axis @y-axis @blur fade(@color, @fade);
  -moz-box-shadow: @x-axis @y-axis @blur fade(@color, @fade);
  box-shadow: @x-axis @y-axis @blur fade(@color, @fade);
}

.shadow() {
    .dropshadow(0, 7px, 20px, 40, @shadow);
}

.button-plain() {
    border: 0;
    .border-radius(0);
    padding: 0;
    font-weight: normal;
    font-size: 1em;
    background-color: transparent;

    &:hover {
        background: none;
        color: inherit;
    }
}
