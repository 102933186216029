@import "panel/includes";
@import "reset.less";
@import "fonts/font-awesome/font-awesome.less";
@import "fonts/opensans.less";
@import "fonts/league-spartan.less";

body {
    font-family: "Open Sans", Helvetica;
    margin: 0px auto;
    -webkit-font-smoothing: antialiased;
    background: @offwhite url('image/header-bg.jpg') no-repeat center top;
    background-size: 100% auto;
}

html, body {
    height: 100%;
}

body {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-grow: 1;
}

header, .main, footer {
    flex-shrink: 0;
}

.flex-spacer {
    flex-grow: 1;
}

.mixin-button {
    border-radius: 20px;
    border: 0;
    color: @white;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    outline: none;
    padding: 7px 20px;
    text-decoration: none;

    &:active {
        position: relative;
        top: 2px;
    }

    &[disabled] {
        background: lighten(@orangered, 20%);
    }

    &:hover:not([disabled]) {
        cursor: pointer;
        background: @dark_button_background;
        color: @white;
    }
}

body > header {
    .user-select();

    a {
        position: relative;
        display: block;
        margin: 0 auto;
        top: 40px;
        width: 950px;

        span {
            position: absolute;
            bottom: -3px;
            left: 0;
            width: 100%;
            font-size: 11px;
            text-transform: uppercase;
            text-decoration: none;
            text-align: center;
            color: white;
        }
    }

    img {
        width: 259px;
    }
}

div.main {
    display: flex;
    flex-direction: column;
    margin: 150px auto 50px;
}

section.main {
    margin: 150px auto 50px;
}

#login {
    margin-top: 20px;
}

section {
    background: @white;

    &.suspended {
        border: 3px solid @red_dark;
        display: flex;
        flex-direction: column;
        font-size: @font-size-0;
        padding: 20px;
        width: 884px;

        > span {
            margin: 7px 0;
        }

        .error {
            color: @red_dark;
            font-size: @font-size-3;
            font-weight: bold;
        }
    }

    &.main {
        border: 1px solid @border;
        width: 930px;
        box-sizing: border-box;
        .flex-container();
        .flex-direction(column);
        .shadow();

        a {
            color: @link;
            text-decoration: none;
        }

        .header {
            text-align: center;
            margin: 80px auto 50px;

            h1 {
                font-size: 36px;
                line-height: 32px;
                font-weight: normal;
                color: @primary_text;
                margin-bottom: 15px;
                strong {
                    font-weight: 600;
                }
            }
            h2 {
                font-size: 18px;
                font-weight: normal;
                color: @label;
            }

            h3 {
                padding-bottom: 15px;
            }
        }

        .warning {
            display: inline-block;
            margin: 0 auto 40px;
            padding: 10px;
            text-align: center;
            color: @white;
            background: @red_dark;
        }

        .content {
            margin: 0 90px 80px;

            .github {
                width: 300px;
                height: 300px;
                box-sizing: border-box;
                background: @offwhite;
                text-align: center;
                padding: 45px 30px 30px;

                &.step {
                    background: @white;
                }

                img {
                    margin-bottom: 20px;
                }

                a {
                    .mixin-button();
                    background: @dark_button_background;
                }
            }

            .half.partners {
                margin-left: 100px;
                width: 320px;
                text-align: center;

                img {
                    width: 100%;
                }

                h2 {
                    font-family: 'League Spartan';
                    margin: 10px 0 15px;
                    text-transform: uppercase;
                    font-size: 20px;
                }

                p {
                    color: @secondary_text;
                    line-height: 18px;
                    font-size: 13px;
                }
            }
        }

        div.error, p.error {
            padding: 5px;
            background: @red;
            color: @white;
            font-size: 14px;
            margin: 10px 0;
        }

        .error_row {
            font-size: 13px;
            color: @red;
            margin-top: 15px;

            i {
                margin-right: 5px;
            }
        }

        .reset_password {
            margin: 0 auto;
            width: 550px;
            padding: 30px;
            box-sizing: border-box;
            background: @offwhite;
            border: 1px solid @border;

            h1 {
                font-size: 24px;
                font-weight: 600;
            }

            h1, p {
                margin-bottom: 20px;
            }

            input {
                background: @white;
                display: block;
            }
        }


        form {

            &#login-form {
                margin-top: 60px;
            }

            p {
                margin-top: 10px;
            }

            label {
                color: #333;
                display: block;
                font-size: 14px;
                font-weight: 600;
                margin: 20px 0 10px;
            }

            .disabled {
                margin-top: 10px;
                font-weight: 600;
            }

            input, select {
                width: 100%;
                padding: 10px;
                background: @offwhite;
                border: 1px solid @border;
                font-size: 16px;
                box-sizing: border-box;

                &.error {
                    border: 1px solid @red;
                }
            }

            select {
                padding: 10px 5px;
            }

            &.error input {
                border: 1px solid @red;
            }

            button {
                .mixin-button();
                margin-top: 30px;
                background: #FF0F18;
                color: @white;
            }
        }

        .half {
            vertical-align: middle;
            display: inline-block;
            width: 300px;

            &.middle {
                font-size: 14px;
                width: 130px;
                text-align: center;
            }
        }

        .application_details {
            padding-bottom: 30px;

            p {
                margin-top: 10px;
                margin-bottom: 10px;
            }

            ul {
                margin-left: 50px;
                text-align: left;
            }
        }

        .separator {
            text-align: center;
            height: 0.5em;
            border-bottom: 1px solid @border;
            margin-bottom: 25px;
            font-size: 11px;
            font-weight: 600;
            text-transform: uppercase;
            color: @diminished_text;

            &:first-line {
                background-color: @white;
            }

            &:before, &:after {
                content: "\a0\a0";
            }

            &.top {
                margin-top: 20px;
            }
        }

        .tos {
            font-size: 11px;
            margin-top: 15px;

            a, a:visited {
                color: @blue;
                text-decoration: none;
            }
        }

        form.accept-deny {
            text-align: center;

            .accept, .deny {
                .mixin-button();
                width: 100px;
                margin: 0px auto;
            }

            .accept {
                background-color: @green_light;
            }

            .deny {
                background-color: @red;
            }
        }
    }
}

#warning {
    margin: 50px auto 55px auto;
    width: 675px;
    margin-top: 55px;
    color: @secondary_text;
    background: #FFFFF8;

    i {
        font-size: 36px;
        float: left;
        margin: 5px 16px 100px 15px;
    }

    p {
        font-size: 11px;
        line-height: 15px;
        padding-top: 15px;
        padding-bottom: 15px;
    }
}

#tos, #privacy, #sla, #aup {
    width: 930px;
    .content {
        line-height: 120%;

        b {
            font-weight: 600;
        }

        p {
            margin: 20px 30px;
        }

        h3 {
            margin: 20px 10px;
            font-weight: 600;
        }

        h4 {
            margin: 20px 20px;
            font-weight: normal;
        }

        ul {
            margin: 20px 60px;
            list-style: circle;

            ul {
                margin: 20px 30px;
            }
        }

        ol {
            margin: 15px 45px;
            list-style: decimal;

            li {
                margin: 10px 0;
                p {
                    margin: 15px;
                }

                & > {
                    h3, h4 {
                        margin-left: 0;
                    }
                }
            }

            ol {
                list-style: lower-alpha;

                ol {
                    list-style: lower-roman;

                    ol {
                        list-style: upper-roman;
                    }
                }
            }
        }
    }

    .error {
        margin: 250px 0;
        text-align: center;
        font-size: 20px;
        background: none;
        color: black;

        p + p {
            margin-top: 30px;
            font-size: 14px;
        }
    }
}

.designOverlay {
    position: absolute; left: 0; top: 0;
    width: 100%; height: 101%;
    text-align: center;
    a {
        position: fixed;
        z-index: 6000;
        width: 40px; height: 40px;
    }
    img {
        position: relative;
        z-index: 5000;
        opacity: 0.3;
    }
    .overlay-off {
        left: 55px; top: 15px;
        background: rgba(113,19,19,0.5);
    }
    .overlay-on {
        left: 15px; top: 15px;
        background: rgba(19,112,20,0.5);
    }
    #no-overlay:target {
        display: none;
    }
}

.back {
    margin-top: 15px;
}

.password-complexity-criteria {
    position: absolute;
    display: none;
    margin-top: 13px;
    padding: 8px 15px 10px 10px;
    .rounded(10px);
    background: white;
    border: 1px solid @border;
    font-size: 14px;

    .pointer {
        height: 12px;
        width: 30px;
        position: absolute;
        top: -12px;
        left: 30px;
        overflow: hidden;

        &:after {
            position: absolute;
            top: 5px;
            left: 5px;
            content: '';
            display: block;
            width: 20px;
            height: 20px;
            background: #FFFFFF;
            border-left: 1px solid darken(@border, 2%);
            border-top: 1px solid darken(@border, 2%);
            -moz-transform:rotate(45deg);
            -webkit-transform:rotate(45deg);
        }
    }

    header {
        color: #666;
        font-weight: bold;
        margin: 0 0 5px 0;
    }

    ul {
        margin-left: 5px;
    }

    li {
        list-style-type: none;
        margin: 6px 0 0;

        .Omnivore_Controls_Icon {
            font-size: inherit;
            margin-right: 0;
            color: @diminished_text;
            float: left;
        }
    }

    li.unfulfilled {
        & > .fa, & > span {
            color: @red_dark;
        }
    }

    li.fulfilled {
        & > .fa, & > span {
            color: @green;
        }
    }

    li.optional {
        ul {
            margin-left: 15px;
            margin-top: 5px;
        }
    }
}

#two-factor-auth-confirm form {
    position: relative;

    #resend-code {
        font-size: 12px;
        visibility: hidden;
        position: absolute;
    }

    .buttons {
        position: relative;
        margin-top: 50px;

        button {
            margin-top: 0;
        }

        a {
            position: absolute;
            right: 0;
            font-size: 12px;
            line-height: 31px;
        }
    }
}

a.provider-login {
    .mixin-button();
    background: @button_background;
    color: #444;
    float: right;
    margin-top: 30px;
    height: 17px;

    &:hover:not([disabled]) {
        cursor: pointer;
        color: #444;
        background: darken(@button_background, 10%);
    }
}

.providers {
    padding: 0 50px 50px 50px;
    display: flex;
    flex-flow: row wrap;

    a.provider {
        .mixin-button();
        padding: 12px 25px;
        display: inline-block;
        text-align: center;
        margin-top: 0px;
        margin-bottom: 20px;
        margin-left: 5px;
        margin-right: 5px;
        flex: 0 1 145px;

        color: #fff;
        background-color: @dark_button_background;

        &.github {
            color: #fff;
            background-color: #444;
        }

        &.lavu {
            color: #fff;
            background-color: #99bc08;
        }

        &:hover:not([disabled]) {
            cursor: pointer;

            &.github {
                background: darken(#444, 10%);
            }
            &.lavu {
                background: darken(#99bc08, 5%);
            }
        }
    }
}

footer {
    background: #EEE;
    color: #333;
    position: relative;
    line-height: 30px;
    border-top: 1px solid #e9e9e9;
    clear: both;
    font-size: 12px;
    width: 100%;
    min-width: 1000px;
    height: 30px;
    z-index: 2;

    a {
        color: inherit;
    }

    .container {
        margin: 0 auto;
        width: 930px;
    }
}
